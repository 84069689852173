import React from 'react'

export const StatusChip = ({ status: value, className = '' }) => {
  return (
    <button
      className={`${
        className ? className : 'bg-white/10'
      } rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
    >
      {value || ''}
    </button>
  )
}
