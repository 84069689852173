import {
  decrementGamesRunning,
  incrementGamesRunning,
} from '@/redux/slices/plinko'
import store from '@/redux/store'

const CASES = {
  LOADING: 'LOADING',
  REFRESH: 'REFRESH',
  SOUND: 'SOUND',
  FLOOR: 'FLOOR',
  BG_SOUND: 'BG_SOUND',
  BALL_OC: 'BALL_OC',
  BALL_SPEED: 'BALL_SPEED',
  LAST_MULTIPLIER: 'LAST_MULTIPLIER',
}

const initialState = {
  isLoading: false,
  refresh: false,
  sound: true,
  floor: 'blue',
  bgSound: true,
  ballLoc: 179,
  ballSpeed: 1,
  lastMultipliers: [],
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CASES.LOADING: {
      return { ...state, isLoading: payload }
    }
    case CASES.REFRESH: {
      return { ...state, refresh: payload }
    }
    case CASES.SOUND: {
      return { ...state, sound: payload }
    }
    case CASES.FLOOR: {
      return { ...state, floor: payload }
    }
    case CASES.BG_SOUND: {
      return { ...state, bgSound: payload }
    }
    case CASES.BALL_OC: {
      return { ...state, ballLoc: payload }
    }

    default:
      return state
  }
}

const stateFunctions = (dispatch) => ({
  setIsLoading: (value) => {
    dispatch({ type: CASES.LOADING, payload: value })
  },
  setRefresh: (value) => {
    dispatch({ type: CASES.REFRESH, payload: value })
  },
  setSound: (value) => {
    dispatch({ type: CASES.SOUND, payload: value })
  },
  setFloor: (value) => {
    dispatch({ type: CASES.FLOOR, payload: value })
  },
  setBgSound: (value) => {
    dispatch({ type: CASES.BG_SOUND, payload: value })
  },
  setBallLoc: (value) => {
    dispatch({ type: CASES.BALL_OC, payload: value })
  },
  setBallSpeed: (value) => {
    dispatch({ type: CASES.BALL_SPEED, payload: value })
  },
  setLastMultipliers: (value) => {
    dispatch({ type: CASES.LAST_MULTIPLIER, payload: value })
  },
})

const dotColor = {
  blue: 'bg-[#333695]',
  green: 'bg-[#3D7D19]',
  yellow: 'bg-[#BEA223]',
  red: 'bg-[#983012]',
}

const floorButtons = {
  vintage: [
    { color: 'blue', bg: 'bg-btn-blue' },
    { color: 'green', bg: 'bg-btn-green' },
    { color: 'yellow', bg: 'bg-btn-yellow' },
    { color: 'red', bg: 'bg-btn-red' },
  ],
  jungle: [
    { color: 'blue', bg: 'bg-btn-jng-blue' },
    { color: 'green', bg: 'bg-btn-jng-green' },
    { color: 'yellow', bg: 'bg-btn-jng-yellow' },
    { color: 'red', bg: 'bg-btn-jng-red' },
  ],
}

const buttonHeights = {
  jungle: 'h-6 sm:h-10',
  vintage: 'h-9 sm:h-16',
}

const floorPriceDolarRate = {
  blue: { min: 0.01, max: 500 },
  green: { min: 0.01, max: 90 },
  yellow: { min: 0.01, max: 22.5 },
  red: { min: 0.01, max: 3.33 },
}

const multiplerCategory = {
  blue: 0x400000,
  green: 0x800000,
  yellow: 0x1000000,
  red: 0x2000000,
}

const floorPriceBsvRate = {
  blue: { min: 0.0031, max: 15.6264 },
  green: { min: 0.0031, max: 2.8127 },
  yellow: { min: 0.0031, max: 0.7032 },
  red: { min: 0.0031, max: 0.1041 },
}

const pins = {
  miniPins: {
    startPins: 3,
    pinSize: 2.5,
    pinGap: 17,
  },
  bigPins: {
    startPins: 3,
    pinSize: 3.7,
    pinGap: 24,
  },
}

const ball = {
  miniBall: {
    ballSize: 4.3,
  },
  bigBall: {
    ballSize: 6,
  },
}

const gameConfigs = (isBigScreen) => {
  if (isBigScreen) {
    return {
      ...pins.bigPins,
      ...ball.bigBall,
      ...world.bigWorld,
    }
  } else {
    return {
      ...pins.miniPins,
      ...ball.miniBall,
      ...world.miniWorld,
    }
  }
}

const engine = {
  engineGravity: (type) => {
    switch (type) {
      case 'slow':
        return 0.5
      case 'fast':
        return 2
      default:
        return 1
    }
  },
}

const world = {
  miniWorld: {
    width: 320,
    height: 422,
  },
  bigWorld: {
    width: 455,
    height: 600,
  },
}

const colors = {
  background: 'transparent',
  primary: '#213743',
  secondary: '#3d5564',
  text: '#F2F7FF',
  purple: '#C52BFF',
  purpleDark: '#8D27B3',
}

export const config = {
  gameConfigs,
  engine,
  colors,
}

const onCollideWithMultiplier = async (
  ball,
  multiplier,
  removeInGameBall,
  Composite,
  getMultiplierSound,
  setIsLoading,
  setLastMultipliers,
  engine,
  lastMultipliers,
) => {
  ball.collisionFilter.group = 2
  removeInGameBall()
  Composite.remove(engine.world, ball)

  const ballValue = ball.label.split('-')[1]
  const multiplierValue = +multiplier.label.split('-')[1]

  const multiplierSong = new Audio(getMultiplierSound(multiplierValue))
  multiplierSong.currentTime = 0
  multiplierSong.volume = 0.2
  multiplierSong.play()
  setIsLoading(false)
  setLastMultipliers([
    multiplierValue,
    lastMultipliers[0],
    lastMultipliers[1],
    lastMultipliers[2],
  ])

  if (+ballValue <= 0) return
}

const onBodyCollision = async (
  event,
  setIsLoading,
  removeInGameBall,
  Composite,
  getMultiplierSound,
  setLastMultipliers,
  engine,
  lastMultipliers,
) => {
  setIsLoading(true)
  const pairs = event.pairs
  for (const pair of pairs) {
    const { bodyA, bodyB } = pair
    if (bodyB.label.includes('ball') && bodyA.label.includes('block')) {
      await onCollideWithMultiplier(
        bodyB,
        bodyA,
        removeInGameBall,
        Composite,
        getMultiplierSound,
        setIsLoading,
        setLastMultipliers,
        engine,
        lastMultipliers,
      )
    }
  }
}

const bet = (addBall, betValue) => {
  addBall(betValue)
}

const onRefresh = (setSound, setBallSpeed, setRefresh) => {
  setSound(true)
  setBallSpeed(1)
  setRefresh((refresh) => !refresh)
}

const onSound = (setSound) => {
  setSound((state) => !state)
}

const onBgSound = (setBgSound) => {
  setBgSound((state) => !state)
}

function addInGameBall(gamesRunning) {
  if (gamesRunning > 0) return
  store.dispatch(incrementGamesRunning())
}

function removeInGameBall() {
  store.dispatch(decrementGamesRunning())
}

export {
  onBodyCollision,
  bet,
  onRefresh,
  onSound,
  onBgSound,
  addInGameBall,
  removeInGameBall,
  initialState,
  reducer,
  CASES,
  stateFunctions,
  floorButtons,
  buttonHeights,
  floorPriceDolarRate,
  floorPriceBsvRate,
  dotColor,
  multiplerCategory,
}
