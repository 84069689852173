import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import NextImage from 'next/image'

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule])

const statusColors = {
  active: 'bg-[#F7941D]/20 text-[#F7941D]',
  push: 'bg-[#F7941D]/20 text-[#F7941D]',
  win: 'bg-[#12B76A]/10 text-[#12B76A]',
  lose: 'bg-[#F04438]/10 text-[#F04438]',
  grey: 'bg-white/10 text-white',
  yellow: 'bg-[#F79009]/20 text-[#F79009]',
  red: 'bg-[#F04438]/10 text-[#F04438]',
  blue: 'bg-[#0096FF]/10 text-[#0096FF]',
  green: 'bg-[#12B76A]/10 text-[#12B76A]',
}

export const calculateCoefficient = (oddData) => {
  if (oddData?.res) {
    return oddData.res
  } else {
    if (oddData.price < 0) {
      return parseFloat(-100 / oddData.price)?.toFixed(2)
    } else {
      return parseFloat(oddData.price / 100)?.toFixed(2)
    }
  }
}

const Table = ({
  allBets,
  bsvToUsd,
  setReplayGame,
  replayGame,
  isLoading,
  onIdPress,
  isHome = false,
  type = null,
  customColor = null,
}) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const ReplayRenderer = (props) => {
    const { bigBall, smallBall, category, floorColor, speed, date } = props.data
    return (
      <button
        onClick={() =>
          setReplayGame({
            bigBall,
            smallBall,
            category,
            floorColor,
            speed,
            date,
          })
        }
        disabled={isLoading || (replayGame && replayGame.date !== date)}
        className="text-sm font-medium rounded-full border border-white disabled:opacity-20 p-1 flex justify-center items-center hover:opacity-50"
      >
        {replayGame && replayGame.date === date ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#eab300"
            className="w-5 h-5 animate-spin"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#eab300"
            className="w-5 h-5 pl-[2px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        )}
      </button>
    )
  }

  const PlinkoDate = (props) => {
    const data = props.data

    return (
      <div className="text-sm font-medium capitalize flex gap-x-5 w-[100%]">
        <div className="p-2">
          <span>{data?.date}</span>
        </div>
      </div>
    )
  }

  const BetIdRenderer = (props) => {
    const data = props.data

    return (
      <div className="text-sm font-medium  cursor-pointer" title={data?.betID}>
        {data?.betID}
      </div>
    )
  }

  const PlinkoResultRenderer = (props) => {
    const data = props.data

    if (data.floorColor === '') {
      return <></>
    }
    return (
      <div
        className={`${
          statusColors[data?.floorColor]
        } capitalize rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
      >
        {data?.floorColor}
      </div>
    )
  }

  const BetRenderer = (props) => {
    const data = props.data
    const bet = data.amount

    return (
      <div className="text-sm font-medium space-y-2 cursor-pointer">
        <div className="flex gap-x-2 items-center relative">
          <NextImage
            width={16}
            height={16}
            src="/svgs/BSV.svg"
            className="w-4 h-4"
            alt="bsv logo"
          />{' '}
          {bet}
        </div>
        <div>$ {Number(bsvToUsd(bet) || 0.0)?.toFixed(5)}</div>
      </div>
    )
  }

  const PayoutRenderer = (props) => {
    const data = props.data
    const earn = data.res * data.amount

    return (
      <div
        className={`text-sm font-medium space-y-2 cursor-pointer ${
          +earn > 0 ? 'text-yellow-600' : 'text-gray-600'
        }`}
      >
        <div className={`flex gap-x-2 items-center relative`}>
          <NextImage
            width={16}
            height={16}
            src="/svgs/BSV.svg"
            className="w-4 h-4"
            alt="bsv"
          />{' '}
          {earn.toFixed(5)}
        </div>
        <div>
          {+earn > 0 ? '+' : earn === 0 ? '' : '-'}${' '}
          {Number(bsvToUsd(earn) || 0.0)?.toFixed(5)}
        </div>
      </div>
    )
  }

  const CoefRenderer = (props) => {
    const data = props.data

    return (
      <div
        className={`${
          statusColors[data.floorColor]
        } capitalize rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
      >
        {calculateCoefficient(data)}
      </div>
    )
  }

  const PlinkoOdds = (props) => {
    const { data, rowIndex, colDef } = props
    const reversedData = [...data?.odds].reverse()
    const selectedRow = rowIndex
    const colIndex =
      colDef.cellRendererParams.colIndex === 0
        ? colDef.cellRendererParams.colIndex
        : colDef.cellRendererParams.colIndex - 1
    const betCount = Array.isArray(reversedData) && reversedData[colIndex]?.bets
    const won = Array.isArray(reversedData) && reversedData[colIndex]?.won

    const rowName = (key) => {
      switch (key) {
        case 0:
          return 'Bet Count'
        case 1:
          return 'Bet Rate'
        case 2:
          return 'Won'
        case 3:
          return 'Won Rate'
      }
    }

    const rowValue = (key) => {
      switch (key) {
        case 0:
          return betCount
        case 1:
          return betCount !== 0
            ? ((betCount / data.totalBets) * 100).toFixed(3)
            : betCount
        case 2:
          return won !== 0 ? won?.toFixed(5) : won
        case 3:
          const rateCalculate =
            Number(((won / data.totalWon) * 100).toFixed(2)) !== 0
              ? ((won / data.totalWon) * 100).toFixed(2)
              : ((won / data.totalWon) * 100).toFixed(3)
          return won !== 0 ? rateCalculate : won
      }
    }

    const keyColorPicker = (key) => {
      switch (key) {
        case 'blue':
          return 'text-blue-300'
        case 'green':
          return 'text-green-300'
        case 'yellow':
          return 'text-yellow-300'
        case 'red':
          return 'text-red-300'
        default:
          return ''
      }
    }
    const valueColorPicker = (key) => {
      switch (key) {
        case 'blue':
          return 'text-blue-500'
        case 'green':
          return 'text-green-500'
        case 'yellow':
          return 'text-yellow-500'
        case 'red':
          return 'text-red-500'
        default:
          return ''
      }
    }
    return (
      <div className="text-sm font-medium capitalize flex justify-center  w-[100%]">
        <div className="p-2 flex flex-col">
          {props.colDef.cellRendererParams.colIndex === 0 ? (
            <>
              <span className={keyColorPicker(customColor)}>
                {colIndex === 0 ? rowName(selectedRow) : rowValue(selectedRow)}
              </span>
            </>
          ) : (
            <span
              className={`${
                Number(rowValue(selectedRow)) !== 0
                  ? valueColorPicker(customColor)
                  : 'text-white'
              }`}
            >
              {rowValue(selectedRow)}
            </span>
          )}
        </div>
      </div>
    )
  }

  const getRowId = useMemo(() => {
    return (params) => params.data.getTx
  }, [])

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }

  const casionColumnDefs = [
    {
      headerName: 'Bet ID',
      field: 'betID',
      suppressMovable: true,
      cellRenderer: BetIdRenderer,
      minWidth: 150,
      maxWidth: 150,
      valueGetter: (params) => {
        const { data } = params
        if (data?.betID) {
          return data?.betID?.toLowerCase()
        }
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Bet',
      field: 'betPrice',
      cellRenderer: BetRenderer,
      suppressMovable: true,
      minWidth: 150,
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Payouts',
      field: 'payouts',
      cellRenderer: PayoutRenderer,
      suppressMovable: true,
      minWidth: 150,
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Time(UTC)',
      field: 'Sport',
      cellRenderer: PlinkoDate,
      suppressMovable: true,
      minWidth: 160,
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Multiplier',
      field: 'multiplier',
      cellRenderer: CoefRenderer,
      suppressMovable: true,
      minWidth: 130,
      maxWidth: 130,
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'My Pick',
      field: 'MyPick',
      cellRenderer: PlinkoResultRenderer,
      suppressMovable: true,
      minWidth: 150,
      maxWidth: 150,
      onCellClicked: onCellClicked,
    },
  ]

  if (!isHome)
    casionColumnDefs.push({
      headerName: 'Replay',
      field: 'replay',
      cellRenderer: ReplayRenderer,
      suppressMovable: true,
      minWidth: 150,
    })

  const statsColumnDefs = (floor) => {
    const headerNames = {
      blue: [
        'Blue',
        'x1.1',
        'x1.1',
        'x1.1',
        'x0.2',
        'x1.1',
        'x1.2',
        'x1.3',
        'x1.4',
        'x3',
      ],
      green: [
        'Green',
        'x0.4',
        'x1',
        'x1.1',
        'x1.2',
        'x1.4',
        'x2',
        'x3',
        'x5',
        'x22',
      ],
      yellow: [
        'Yellow',
        'x0.3',
        'x0.5',
        'x1',
        'x1.4',
        'x3',
        'x5',
        'x12',
        'x38',
        'x111',
      ],
      red: [
        'Red',
        'x0.2',
        'x0.2',
        'x0.2',
        'x2',
        'x4',
        'x9',
        'x24',
        'x130',
        'x999',
      ],
    }

    return headerNames[floor].map((name, idx) => ({
      headerName: name,
      field: 'odd',
      suppressMovable: true,
      cellRenderer: PlinkoOdds,
      width: 120,
      cellRendererParams: {
        colIndex: idx,
      },
    }))
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
    }
  }, [])

  return (
    <div
      className={`${customColor ? 'h-[310px]' : 'h-[500px]'} pb-[0.5] mx-auto ${
        isHome ? 'my-8  w-[100%]' : 'my-2 w-[95%] md:w-[97%] lg:max-w-7xl'
      } bg-black/70 rounded-md`}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-alpine-dark">
        {customColor ? (
          <AgGridReact
            rowData={allBets ? [allBets, allBets, allBets, allBets] : null}
            columnDefs={statsColumnDefs(customColor)}
            id="myGrid"
            className="my-custom-grid"
            animateRows={true}
            getRowHeight={() => 65}
            overlayLoadingTemplate={'<span class="plinko-loader"></span>'}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
            }
          ></AgGridReact>
        ) : (
          <AgGridReact
            rowData={allBets}
            columnDefs={casionColumnDefs}
            defaultColDef={defaultColDef}
            id="myGrid"
            rowClass={'cursor-pointer'}
            className="my-custom-grid"
            animateRows={true}
            getRowId={getRowId}
            getRowHeight={() => 65}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; ">No Results Found</span>'
            }
            overlayLoadingTemplate={'<span class="plinko-loader"></span>'}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit()
            }}
          ></AgGridReact>
        )}
      </div>
    </div>
  )
}

export default Table
